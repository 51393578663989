/*******************************************************************************
 * (C) Copyright
 * Logivations GmbH, Munich 2010-2024
 ******************************************************************************/

import * as Sentry from '@sentry/browser';

Sentry.init({
	dsn: 'https://899bd1aa8ff64d9f8a494cf03d607135@sentry.io/109847',
	environment: NODE_ENV,
	release: SENTRY_RELEASE_VERSION,
	allowUrls: [
		/w2mo\.logivations\.com/,
		/w2mo\-2\.logivations\.com/
	],
	denyUrls: [
		/localhost/
	],
	enabled: USE_SENTRY,
	beforeBreadcrumb(breadcrumb, hint) {
		if (breadcrumb.category === 'xhr') {
			return breadcrumb.data && breadcrumb.data.status_code !== 200 ? breadcrumb : null;
		}
		return breadcrumb;
	}
});

export default Sentry;
